import React from "react";
import { useStateContext } from "../contexts/ContextProvider";
import { useSubscription } from "@apollo/client";
import useAuth from "../hooks/useAuth";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import TextField from "../components/TextField";
import SubmitButton from "../components/SubmitButton";
import SelectBoxUserName from "../components/SelectUserName";
import { Button, Textarea } from "../components";
import { hasBurmeseCharacters, isValidKey } from "../lib/hasBurmeseCharacters";
import { validateMessage } from "../lib/validateMessage";
import { GET_ALL_ADMIN_SUB } from "../graphql/subscriptions/GetAllAdmin";
import { hasEmoji } from "../lib/hasEmoji";
import { saveAs } from "file-saver";
import ExcelJS from "exceljs";
import imagePath from "../data/Excel_axpigeon_template.xlsx";
import { transformData } from "../lib/readExcel";
const Dashboard = () => {
  const { currentColor } = useStateContext();
  const { userId } = useAuth();
  console.log(userId);
  const [error, setError] = React.useState({
    message: "",
  });
  const [loading, setLoading] = React.useState(false);
  const [selectedName, setSelectName] = React.useState();
  const [isSingleSMS, setIsSingleSMS] = React.useState(true);
  const [phoneNumbers, setPhoneNumbers] = React.useState([]);
  const [mytelPhoneNumbersList, setMytelPhoneNumbersList] = React.useState([]);
  const [errorMessage, setErrorMessage] = React.useState({
    senderID: "",
    sms_reference_id: "",
  });

  const { data } = useSubscription(GET_ALL_ADMIN_SUB, {
    variables: {
      id: userId,
    },
  });
  console.log(data);
  const providers =
    Array.isArray(data?.admin_by_pk.providers) &&
    data?.admin_by_pk.providers.length > 0
      ? data?.admin_by_pk.providers
      : [];

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isDirty, isValid, touchedFields },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      phone: "",
      message: "",
    },
  });
  const customers =
    Array.isArray(data?.admin_by_pk?.customers) &&
    data?.admin_by_pk?.customers.length > 0
      ? data?.admin_by_pk?.customers
      : [];
  const totalSMSForCustomer = customers?.reduce(
    (sum, { customer_provider }) => sum + customer_provider.sms_package,
    0
  );
  let isHasBurmese = hasBurmeseCharacters(watch("message"));
  isHasBurmese = hasEmoji(watch("message"));
  const sendSMS = async (value) => {
    try {
      setLoading(true);
      const response = await fetch(
        `https://api.axpigeon.axra.app/rest/api/sendSMS`,
        // `http://localhost:9009/api/sendsms`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            input: {
              sms_reference_id: value.sms_reference_id,
              phone: value.phone,
              message_body: value.message_body,
              message_title: value.message_title,
            },
          }),
        }
      );
      await response.json();

      setLoading(false);
    } catch (error) {
      console.error("Error sending SMS:", error);
    }
  };
  const sendBulkSMSForMytel = async (value) => {
    try {
      setLoading(true);
      const response = await fetch(
        `https://api.axpigeon.axra.app/rest/api/bulk/sendSMS`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            sms_reference_id: value.sms_reference_id,
            listPhoneNumber: mytelPhoneNumbersList,
            content: value.content,
            brandName: value.brandName,
          }),
        }
      );
      await response.json();
      setLoading(false);
    } catch (error) {
      console.error("Error sending SMS:", error);
    }
  };
  const onSubmit = async ({ phone, message }) => {
    const checkMessage = message.includes(`${selectedName?.username}`);
    if (!checkMessage && selectedName?.username) {
      toast.error(`You Must contain ${selectedName?.username} in message`);
    }
    try {
      if (!selectedName?.username) {
        setErrorMessage({
          ...errorMessage,
          senderID: "Sender ID is required!",
        });
      }

      if (selectedName?.username && checkMessage) {
        await sendSMS({
          phone,
          sms_reference_id: selectedName?.customer_provider?.sms_reference_id,
          message_title: selectedName?.username,
          message_body: message,
        });
        toast.success("SMS Sent Successfully!");
        setValue("message", "");
        setValue("phone", "");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const bulkSMS = async () => {
    try {
      setLoading(true);
      if (!selectedName?.username) {
        setErrorMessage({
          ...errorMessage,
          senderID: "Sender ID is required!",
        });
      }
      if (
        selectedName?.username === "ALPHA MM" ||
        selectedName?.username === "BONCHON MM" ||
        selectedName?.username === "CB Life"
      ) {
        //do here
        await sendBulkSMSForMytel({
          sms_reference_id: selectedName?.customer_provider?.sms_reference_id,
          brandName: selectedName?.username,
          content: phoneNumbers[0]["Message"],
        });
      } else {
        if (selectedName?.username || phoneNumbers.length > 0) {
          for (const p of phoneNumbers) {
            await sendSMS({
              phone: p["Phone No"],
              sms_reference_id:
                selectedName?.customer_provider?.sms_reference_id,
              message_title: selectedName?.username,
              message_body: p["Message"],
            });
          }
          setLoading(false);
          toast.success("SMS Sent Successfully!");
        } else {
          setLoading(false);
          toast.error(`Phone Number and Message is empty!`);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSingleSMS = () => {
    setIsSingleSMS(true);
    setValue("message", "");
    setValue("phone", "");
  };
  const handleBulkSMS = () => {
    setIsSingleSMS(false);
    setValue("message", "");
    setValue("phone", "");
  };

  const downloadTemplate = () => {
    fetch(imagePath)
      .then((response) => response.blob())
      .then((blob) => {
        // Format the current date and time for the file name
        const timestamp = new Date().toISOString().replace(/[:.]/g, "_");

        // Save the blob as a file
        saveAs(blob, `Excel_Axpigeon_SMS_Template_${timestamp}.xlsx`);
      })
      .catch((error) => console.error("Error downloading image:", error));
  };
  const handleChangeFile = (e) => {
    const fileValue = e.target.files[0];

    if (fileValue) {
      async function fineReader(file) {
        const reader = new FileReader();
        reader.onload = async (e) => {
          const data = e.target.result;
          const workbook = new ExcelJS.Workbook();

          // Load the workbook
          await workbook.xlsx.load(data);

          // Assuming there is only one sheet in the workbook
          const worksheet = workbook.worksheets[0];
          console.log("worksheet", worksheet);

          // Convert sheet data to JSON
          const jsonData = worksheet.getSheetValues();
          const transformedData = transformData(jsonData);
          setPhoneNumbers(transformedData);
          setMytelPhoneNumbersList(transformedData.map((p) => p["Phone No"]));
        };

        reader.readAsArrayBuffer(file);
      }
      fineReader(fileValue);
    }
  };

  React.useEffect(() => {
    if (phoneNumbers.length > 0) {
      for (let index = 0; index < phoneNumbers.length; index++) {
        const element = phoneNumbers[index];
        if (
          !isValidKey(element, "Message") ||
          !isValidKey(element, "Phone No")
        ) {
          toast.error(
            `Invalid Excel Template. Please download receiver template`
          );
          break;
        }
        if (element["Message"].includes(selectedName?.username)) {
          continue;
        }
        if (!element["Message"].includes(selectedName?.username)) {
          toast.error(
            `You must contain with "${selectedName?.username}" in message.`
          );
          setError({
            message: element["Message"],
          });
          break;
        }
      }
    }
  }, [phoneNumbers, selectedName?.username]);
  return (
    <>
      <div className="m-2 md:mx-10 sm:mt-0 mt-24 p-2 md:p-10 rounded-3xl">
        {Array.isArray(providers) &&
          providers.length > 0 &&
          providers.map((p) => (
            <div key={p.id} className="grid pb-4 grid-cols-12  gap-4 sm:gap-6">
              <div className="md:col-span-6 col-span-12 lg:col-span-4">
                <div className="p-4 flex flex-col justify-center items-center dark:bg-secondary-dark-bg bg-white rounded-lg">
                  <h4
                    className="text-xl font-bold"
                    style={{ color: currentColor }}
                  >
                    {p?.sms_package || ""}
                  </h4>
                  <p className="text-sm mt-3 font-bold dark:text-white text-gray-600">
                    {p?.provider_name || ""} sms package
                  </p>
                </div>
              </div>
              <div className="md:col-span-6 col-span-12 lg:col-span-4">
                <div className="p-4 flex flex-col justify-center items-center dark:bg-secondary-dark-bg bg-white rounded-lg">
                  <h4
                    className=" text-xl font-bold"
                    style={{ color: currentColor }}
                  >
                    {p?.sms_package - p?.used_sms || ""}
                  </h4>
                  <p className="mt-3 text-sm font-bold dark:text-white text-gray-600">
                    {p?.provider_name} available sms
                  </p>
                </div>
              </div>
              <div className="md:col-span-6 col-span-12 lg:col-span-4">
                <div className="p-4 flex flex-col justify-center items-center dark:bg-secondary-dark-bg bg-white rounded-lg">
                  <h4
                    className="text-xl font-bold"
                    style={{ color: currentColor }}
                  >
                    {p?.used_sms}
                  </h4>
                  <p className="text-sm mt-3 font-bold dark:text-white text-gray-600">
                    {p?.provider_name} used sms
                  </p>
                </div>
              </div>
            </div>
          ))}

        <div className="grid grid-cols-12 pt-4 border-dashed border-t-2 gap-4 sm:gap-6">
          <div className="md:col-span-6 col-span-12 lg:col-span-3">
            <div className="p-4 flex flex-col justify-center items-center dark:bg-secondary-dark-bg bg-white rounded-lg">
              <h4
                className=" text-xl font-bold"
                style={{ color: currentColor }}
              >
                {data?.admin_by_pk?.sms_package}
              </h4>
              <p className="text-sm mt-3 font-bold dark:text-white text-gray-600">
                Total sms package
              </p>
            </div>
          </div>

          <div className="md:col-span-6 col-span-12 lg:col-span-3">
            <div className="p-4 flex flex-col justify-center items-center dark:bg-secondary-dark-bg bg-white rounded-lg">
              <h4
                className=" text-xl font-bold"
                style={{ color: currentColor }}
              >
                {totalSMSForCustomer}
              </h4>
              <p className="text-sm mt-3 font-bold dark:text-white text-gray-600">
                Total All Customer SMS
              </p>
            </div>
          </div>
          <div className="md:col-span-6  col-span-12 lg:col-span-3">
            <div className="p-4 flex flex-col justify-center items-center dark:bg-secondary-dark-bg bg-white rounded-lg">
              <h4 className="text-xl font-bold" style={{ color: currentColor }}>
                {data?.admin_by_pk?.available_sms}
              </h4>
              <p className="mt-3 text-sm font-bold dark:text-white text-gray-600">
                Total available sms
              </p>
            </div>
          </div>
          <div className="md:col-span-6 col-span-12 lg:col-span-3">
            <div className="p-4 flex flex-col justify-center items-center dark:bg-secondary-dark-bg bg-white rounded-lg">
              <h4
                className=" text-xl font-bold"
                style={{ color: currentColor }}
              >
                {data?.admin_by_pk?.used_sms}
              </h4>
              <p className="mt-3 text-sm font-bold dark:text-white text-gray-600">
                Total used sms
              </p>
            </div>
          </div>
        </div>
        <div className="border border-gray-400 mt-14">
          <h4 className="p-2 dark:text-white text-inherit">
            What is the allowed character for each SMS?
          </h4>
          <table className="w-full">
            <thead>
              <tr className="border border-gray-400">
                <th className="dark:text-white bg-slate-100 dark:bg-transparent text-black font-semibold text-sm  p-3">
                  English
                </th>
                <th className="dark:text-white bg-slate-100 dark:bg-transparent text-black font-semibold text-sm  p-3">
                  Myanmar
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className="border border-gray-400">
                <td className="text-gray-400 border-r font-normal">
                  <p className=" p-2">
                    {" "}
                    1 Standard SMS message = up to 160 characters
                  </p>
                  <p className=" p-2">
                    {" "}
                    2 Concatenated SMS message = up to 306 characters
                  </p>
                  <p className=" p-2">
                    {" "}
                    3 Concatenated SMS message = up to 459 characters
                  </p>
                  <p className=" p-2">
                    {" "}
                    4 Concatenated SMS message = up to 612 characters
                  </p>
                  <p className=" p-2">
                    {" "}
                    5 Concatenated SMS message = up to 765 characters
                  </p>
                </td>
                <td className="text-gray-400 font-normal">
                  <p className=" p-2">
                    {" "}
                    1 Standard SMS message = up to 70 characters
                  </p>
                  <p className=" p-2">
                    {" "}
                    2 Concatenated SMS message = up to 134 characters
                  </p>
                  <p className=" p-2">
                    {" "}
                    3 Concatenated SMS message = up to 201 characters
                  </p>
                  <p className=" p-2">
                    {" "}
                    4 Concatenated SMS message = up to 268 characters
                  </p>
                  <p className=" p-2">
                    {" "}
                    5 Concatenated SMS message = up to 335 characters
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="flex flex-col justify-center items-center">
          <div className="mt-10 p-8 rounded-3xl sm:w-[500px]  w-full bg-white dark:bg-secondary-dark-bg">
            <h2 className="text-3xl font-semibold text-gray-400 text-center mb-6">
              Send SMS
            </h2>
            <div className="grid grid-cols-12 border-b text-center  rounded mb-4 ">
              <div
                onClick={handleSingleSMS}
                className={`col-span-6  cursor-pointer hover:text-gray-500 py-2 ${
                  isSingleSMS
                    ? "text-gray-600 dark:text-white border-b-2"
                    : "text-gray-400"
                }`}
                style={{ borderColor: currentColor }}
              >
                Single SMS
              </div>
              <div
                onClick={handleBulkSMS}
                className={`col-span-6  cursor-pointer hover:text-gray-500 py-2 ${
                  !isSingleSMS
                    ? "text-gray-600 dark:text-white border-b-2"
                    : "text-gray-400"
                }`}
                style={{ borderColor: currentColor }}
              >
                Bulk SMS
              </div>
            </div>
            {!isSingleSMS && (
              <div className="dark:text-gray-400 text-black text-sm text-center my-4">
                Please fill data using this{" "}
                <span
                  onClick={downloadTemplate}
                  className="text-blue-600 cursor-pointer font-semibold"
                >
                  Receiver Template
                </span>{" "}
                before uploading
              </div>
            )}
            {isSingleSMS ? (
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="grid grid-cols-12 gap-4">
                  <div className="col-span-12">
                    <div>
                      <h4 className="mb-2 dark:text-gray-400 ">Sender ID</h4>
                      <SelectBoxUserName
                        name="Sender ID"
                        data={customers}
                        selected={selectedName}
                        setSelected={setSelectName}
                        errorMsg={errorMessage.senderID}
                      />
                    </div>
                  </div>
                  <div className="col-span-12">
                    <div>
                      <h4 className="mb-2 dark:text-gray-400 ">Phone</h4>
                      <div>
                        <TextField
                          {...register("phone", {
                            required: "phone is required field",
                            pattern: {
                              value: /^(09|\+959)\d{9}$/,
                              message: "Invalid phone number format",
                            },
                          })}
                          disabled={loading}
                          inputSize={"medium"}
                          fullWidth
                          placeholder={"09********* or 959*********"}
                          type="text"
                          error={
                            touchedFields.phone &&
                            errors.phone &&
                            Boolean(errors.phone)
                          }
                          helperText={
                            touchedFields.phone &&
                            errors.phone &&
                            errors.phone.message
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-span-12">
                    <div>
                      <h4 className="mb-2 dark:text-gray-400 ">Message</h4>
                      <Textarea
                        {...register("message", {
                          required: "message is required field",
                        })}
                        fullWidth
                        placeholder="Message"
                        error={
                          touchedFields.message &&
                          errors.message &&
                          Boolean(errors.message)
                        }
                        helperText={
                          touchedFields.message &&
                          errors.message &&
                          errors.message.message
                        }
                      />
                    </div>
                  </div>
                </div>

                <div className="mt-4">
                  <SubmitButton
                    type="submit"
                    disabled={loading || !(isValid && isDirty)}
                    text={loading ? "Sending..." : "Send"}
                    borderRadius={8}
                    bgColor={
                      loading || !(isValid && isDirty) ? "grey" : currentColor
                    }
                    color="#fff"
                  />
                </div>
              </form>
            ) : (
              <>
                <div className="grid grid-cols-12  gap-4">
                  <div className="col-span-12">
                    <h4 className="mb-2 dark:text-gray-400 ">Sender ID</h4>
                    <SelectBoxUserName
                      name="Sender ID"
                      data={customers}
                      selected={selectedName}
                      setSelected={setSelectName}
                      errorMsg={errorMessage.senderID}
                    />
                  </div>
                  <div className="col-span-12">
                    <h4 className="mb-2 dark:text-gray-400 ">Upload File</h4>
                    <TextField
                      fullWidth
                      type="file"
                      onChange={(e) => handleChangeFile(e)}
                    />
                  </div>
                  <div className="mt-4">
                    <Button
                      onClick={bulkSMS}
                      type={"button"}
                      disabled={loading || error.message !== ""}
                      text={loading ? "Sending" : "Send"}
                      borderRadius={8}
                      bgColor={
                        loading || error.message !== "" ? "grey" : currentColor
                      }
                      color="#fff"
                    />
                  </div>
                </div>
              </>
            )}
            <div className="flex flex-row justify-end text-gray-400">
              {watch("message").length > 0 && (
                <div className="flex flex-row items-center space-x-2">
                  <span>{isHasBurmese ? "Myanmar" : "English"} =</span>
                  <p>
                    {watch("message").length} /{" "}
                    {validateMessage({
                      messageLength: watch("message").length,
                      is_burmese: isHasBurmese,
                    })}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Dashboard;
