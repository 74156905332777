import { gql } from "@apollo/client";

export const getAllCustomerTransactions = gql`
  query MyQuery($id: uuid!) {
    customers_by_pk(id: $id) {
      phone
      username
      email
      id
      status
      username
      customer_provider {
        sms_package
        used_sms
        mpt_sms
        atom_sms
        ooredoo_sms
        mytel_sms
      }
    }
  }
`;
