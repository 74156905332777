import { gql } from "@apollo/client";

export const GET_ALL_TRANSACTIONS_BY_ID = gql`
  query Query(
    $limit: Int
    $offset: Int
    $id: uuid!
    $datefilter: timestamptz_comparison_exp
  ) {
    transaction_reports(
      limit: $limit
      offset: $offset
      order_by: { created_at: desc }
      where: { customer: { id: { _eq: $id } }, created_at: $datefilter }
    ) {
      id
      message_body
      message_length
      message_title
      mobile_phone
      operator_name
      reason
      status
      transaction_code
      updated_at
      fk_customer_id
      created_at
    }
    transaction_reports_aggregate(
      where: { fk_customer_id: { _eq: $id }, created_at: $datefilter }
    ) {
      aggregate {
        count
      }
    }
  }
`;
