import { ApolloClient, HttpLink, InMemoryCache, split } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import { setContext } from "@apollo/client/link/context";
import { getMainDefinition } from "@apollo/client/utilities";
import { IS_CLIENT } from "../config/common";
import { ACCESS_TOKEN } from "../config/app";
const authLink = setContext((_, { headers }) => {
  let accessToken = IS_CLIENT && window.localStorage.getItem(ACCESS_TOKEN);
  return {
    headers: {
      ...headers,
      ...(accessToken && { Authorization: `Bearer ${accessToken}` }),
    },
  };
});

const httpLink = new HttpLink({
  uri: "https://api.axpigeon.axra.app/v1/graphql",
  // uri: "http://localhost:9000/v1/graphql",
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ extensions }) => {
      if (
        extensions.code === "invalid-headers" ||
        extensions.code === "invalid-jwt"
      ) {
        window.location.assign(`${window.location.origin}/login`);
      }
    });
  }
  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
    alert("network connection problem");
  }
});
const wsLink = new GraphQLWsLink(
  createClient({
    // url: "ws://localhost:9000/v1/graphql",
    url: "wss://api.axpigeon.axra.app/v1/graphql",
    shouldRetry: () => true,
    retryAttempts: 5,
    connectionParams: async () => {
      let accessToken = IS_CLIENT && window.localStorage.getItem(ACCESS_TOKEN);
      return {
        headers: {
          ...(accessToken && { Authorization: `Bearer ${accessToken}` }),
        },
      };
    },
  })
);

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink
);

export const apolloClient = new ApolloClient({
  link: errorLink.concat(authLink).concat(splitLink),
  // link: splitLink,
  cache: new InMemoryCache(),
  ssrMode: typeof window === "undefined",
});
