import { gql } from "@apollo/client";

export const GET_ALL_TRANSACTIONS = gql`
  query MyQuery(
    $datefilter: timestamptz_comparison_exp
    $limit: Int
    $offset: Int
  ) {
    transaction_reports(
      limit: $limit
      offset: $offset
      where: { created_at: $datefilter }
      order_by: { created_at: desc }
    ) {
      id
      message_body
      message_length
      message_title
      mobile_phone
      operator_name
      reason
      status
      transaction_code
      updated_at
      fk_customer_id
      created_at
    }
    customers {
      id
      username
    }
    transaction_reports_aggregate(where: { created_at: $datefilter }) {
      aggregate {
        count
      }
    }
  }
`;
