import React from "react";
import Header from "../components/Header";
import { useQuery } from "@apollo/client";
import { GET_ALL_TRANSACTIONS } from "../graphql/queries/getAllTransaction";
import ExcelJS from "exceljs";
import { SelectBox, TextField } from "../components";
import { formatDate } from "../data/dummy";
import ReactDatePicker from "react-datepicker";
import { groupBy } from "lodash";
import Pagination from "../components/Pagination";
const TransactionReports = () => {
  const [selected, setSelected] = React.useState({});
  const [selectedSenderID, setSelectedSenderID] = React.useState({});
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [count, setCount] = React.useState(0);
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);
  const [startDate, setStartDate] = React.useState(currentDate);
  const [endDate, setEndDate] = React.useState(new Date());
  const [searchPhone, setSearchPhone] = React.useState();

  const { data } = useQuery(GET_ALL_TRANSACTIONS, {
    variables: {
      datefilter:
        startDate && endDate
          ? { _gte: startDate.toISOString(), _lte: endDate.toISOString() }
          : { _is_null: false },
      limit: rowsPerPage,
      offset: page * rowsPerPage,
    },
  });
  const countValue = data?.transaction_reports_aggregate?.aggregate?.count;
  React.useEffect(() => {
    if (countValue !== undefined) {
      setCount(countValue);
    }
  }, [countValue]);
  const customers =
    Array.isArray(data?.customers) && data?.customers.length > 0
      ? data?.customers
      : [];
  const transaction_reports =
    Array.isArray(data?.transaction_reports) &&
    data?.transaction_reports.length > 0
      ? data?.transaction_reports
      : [];
  const excelData = [];
  for (let i = 0; i < transaction_reports.length; i++) {
    const element = transaction_reports[i];
    const data = {
      TransactionID: element.id,
      Date: formatDate(element.created_at),
      Mobile_Phone: element.mobile_phone,
      SenderID: element.message_title,
      Content: element.message_body,
      Status: element.status,
      MessageLength: element.message_length,
    };
    excelData.push(data);
  }
  const exportToExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sheet1");

    // Add headers
    const headers = Object.keys(excelData[0]);
    worksheet.addRow(headers);

    // Add data
    excelData.forEach((row) => {
      worksheet.addRow(Object.values(row));
    });

    // Apply styles
    worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
      row.eachCell((cell, colNumber) => {
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
        cell.alignment = { vertical: "middle", horizontal: "center" };
        // Add more styling properties as needed
      });
    });

    // Save the workbook
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `axpigeon_${formatDate(new Date())}.xlsx`;
    a.click();
    URL.revokeObjectURL(url);
  };
  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const getTransaction = (transactionData) => {
    return Object.entries(
      groupBy(
        transactionData
          .filter((p) => (!searchPhone ? p : p.mobile_phone === searchPhone))
          .filter((s) =>
            !selectedSenderID?.username
              ? s
              : s.message_title === selectedSenderID?.username
          )
          .filter((status) =>
            !selected?.username || selected?.username === "ALL"
              ? status
              : status.status === selected?.username
          )
      )
    );
  };

  return (
    <div className="m-2 md:mx-10 sm:mt-0 mt-16 p-2 md:p-10 bg-white dark:bg-secondary-dark-bg rounded-3xl">
      <Header category={"Reports"} title={"Transaction Reports"} />

      <div className=" overflow-x-auto w-full">
        <div className="mb-4 grid grid-cols-12 gap-4 ">
          <div className="lg:col-span-9 col-span-12">
            <div className="grid grid-cols-12 gap-4">
              <div className="lg:col-span-4 md:col-span-3 sm:col-span-6 col-span-12">
                <h4 className="text-black dark:text-white">Filter By Phone</h4>
                <TextField
                  inputSize="small"
                  fullWidth
                  className="placeholder:text-sm"
                  placeholder="Search By Phone"
                  onChange={(e) => setSearchPhone((prev) => e.target.value)}
                />
              </div>
              <div className="lg:col-span-4 md:col-span-3 sm:col-span-6 col-span-12">
                <h4 className="text-black dark:text-white">
                  Filter By SenderID
                </h4>
                <div className="w-full">
                  <SelectBox
                    name="Filter By SenderID"
                    data={customers}
                    selected={selectedSenderID}
                    setSelected={setSelectedSenderID}
                  />
                </div>
              </div>
              <div className="lg:col-span-4 md:col-span-3 sm:col-span-6 col-span-12">
                <h4 className="text-black dark:text-white">Filter By Status</h4>
                <div className="w-full">
                  <SelectBox
                    name="Filter By Status"
                    data={[
                      { id: 1, username: "ALL" },
                      { id: 2, username: "SUCCESS" },
                      { id: 5, username: "FAILED" },
                    ]}
                    selected={selected}
                    setSelected={setSelected}
                  />
                </div>
              </div>
              <div className="lg:col-span-4 md:col-span-3 sm:col-span-6 col-span-12">
                <h4 className="text-black dark:text-white">
                  Filter By From Date
                </h4>
                <label
                  htmlFor="fromDate"
                  className="border cursor-pointer block px-3 py-[9px] rounded-lg"
                >
                  <ReactDatePicker
                    id="fromDate"
                    className=" bg-transparent w-full outline-none dark:text-white border-none text-gray-400 cursor-pointer placeholder:text-sm"
                    placeholderText="Filter By From Date"
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                  />
                </label>
              </div>
              <div className="lg:col-span-4 md:col-span-3 sm:col-span-6 col-span-12">
                <h4 className="text-black dark:text-white">
                  Filter By To Date
                </h4>
                <label
                  htmlFor="toDate"
                  className="border cursor-pointer block px-3 py-[9px] rounded-lg"
                >
                  <ReactDatePicker
                    id="toDate"
                    className=" bg-transparent w-full outline-none dark:text-white border-none text-gray-400 cursor-pointer placeholder:text-sm"
                    placeholderText="Filter By To Date"
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                  />
                </label>
              </div>
            </div>
          </div>
          <div className="lg:col-span-3 col-span-12">
            <button
              onClick={exportToExcel}
              className="bg-green-600 mt-5  w-full block px-4 py-2 rounded text-white border-none"
            >
              Excel Export
            </button>
          </div>
        </div>
        <table className="w-full">
          <thead>
            <tr>
              <th className="dark:text-white bg-slate-100 dark:bg-transparent text-black font-semibold text-sm border p-3">
                No.
              </th>
              <th className="dark:text-white bg-slate-100 dark:bg-transparent text-black font-semibold text-sm border p-3">
                TransactionID
              </th>
              <th className="dark:text-white bg-slate-100 dark:bg-transparent text-black font-semibold text-sm border p-3">
                Date/Time
              </th>
              <th className="dark:text-white bg-slate-100 dark:bg-transparent text-black font-semibold text-sm border p-3">
                MobileNo
              </th>
              <th className="dark:text-white bg-slate-100 dark:bg-transparent text-black font-semibold text-sm border p-3">
                Sender ID
              </th>
              <th className="dark:text-white bg-slate-100 dark:bg-transparent text-black font-semibold text-sm border p-3">
                Operator Name
              </th>
              <th className="dark:text-white bg-slate-100 dark:bg-transparent text-black font-semibold text-sm border p-3">
                MessageBody
              </th>
              <th className="dark:text-white bg-slate-100 dark:bg-transparent text-black font-semibold text-sm border p-3">
                Status
              </th>
              <th className="dark:text-white bg-slate-100 dark:bg-transparent text-black font-semibold text-sm border p-3">
                MessageLength
              </th>
            </tr>
          </thead>
          <tbody>
            {getTransaction(transaction_reports).length ? (
              getTransaction(transaction_reports).map((d, index) =>
                d[1].map((transaction, index) => (
                  <tr key={transaction.id}>
                    <td className="dark:text-white text-black font-normal text-sm border p-2">
                      {index + 1}
                    </td>
                    <td className="dark:text-white text-black font-normal text-sm border p-2">
                      {transaction.id}
                    </td>
                    <td className="dark:text-white text-black font-normal text-sm border p-2">
                      {formatDate(transaction.created_at)}
                    </td>
                    <td className="dark:text-white text-black font-normal text-sm border p-2">
                      {transaction.mobile_phone}
                    </td>
                    <td className="dark:text-white text-black font-normal text-sm border p-2">
                      {transaction.message_title}
                    </td>
                    <td className="dark:text-white text-black font-normal text-sm border p-2">
                      {transaction.operator_name}
                    </td>
                    <td className="dark:text-white text-black font-normal text-sm border p-2">
                      {transaction.message_body}
                    </td>
                    <td className="dark:text-white text-black font-normal text-sm border p-2">
                      {transaction.status}
                    </td>
                    <td className="dark:text-white text-black font-normal text-sm border p-2">
                      {transaction.message_length}
                    </td>
                  </tr>
                ))
              )
            ) : (
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td className="py-8 text-gray-400">No data found</td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            )}
          </tbody>
        </table>
        <div>
          <Pagination
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[10, 25, 100]}
            count={count}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </div>
    </div>
  );
};

export default TransactionReports;
